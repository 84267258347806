import {
    Home as DashboardIcon,
    People as AuditIcon,
    Settings as AdminIcon,
    Favorite as IncidentsIcon,
    Visibility as MonitoringIcon,
    Create as RiskAssessmentsIcon,
    Beenhere as ComplianceDetailIcon,
    Speed as SafetyNestScoreIcon,
    Timeline as InsightsIcon,
    Add as AddIcon,
} from "@material-ui/icons";
import moduleAreas, { moduleUrls } from "./constants/moduleAreaConstants";
import Dashboard from "./pages/Dashboard";
import Audit from "./pages/Audit";
import ComplianceAdmin from "./pages/admin/ComplianceAdmin";
import IncidentsAdmin from "./pages/admin/IncidentsAdmin";
import MonitoringAdmin from "./pages/admin/MonitoringAdmin";
import RiskAssessmentsAdmin from "./pages/admin/RiskAssessmentsAdmin";
import TemplateBuilder from "./pages/admin/TemplateBuilder";
import Compliance from "./pages/Compliance";
import Incidents from "./pages/Incidents";
import Monitoring from "./pages/Monitoring";
import RiskAssessments from "./pages/RiskAssessments";
import CompleteTemplateForm from "./pages/CompleteTemplateForm";
import ViewFormHistory from "./pages/ViewFormHistory";
import Action from "./pages/Action";
import AddTemplateForm from "./pages/AddTemplateForm";
import AddEditIncident from "./pages/incidents/AddEditIncident";
import AddRiskAssessment from "./pages/riskassessments/AddRiskAssessment";
import ReviewRiskAssessment from "./pages/riskassessments/ReviewRiskAssessment";
import { salesforceRoles } from "../constants/roleConstants";
import ViewRiskAssessment from "./pages/riskassessments/ViewRiskAssessment";
import { userAccessPermissionConstant } from "../constants/userAccessPermissionConstants";
import SafetyNestScore from "./pages/SafetyNestScore";
import SiteSearch from "./pages/admin/SiteSearch";
import HistoricAudits from "./pages/admin/HistoricAudits";
import EditHistoricAudit from "./pages/admin/EditHistoricAudit";
import CreateHistoricAudit from "./pages/admin/CreateHistoricAudit";
import MeridianAudits from "./pages/admin/MeridianAudits";
import AuditInsights from "./pages/insights/AuditInsights";
import ComplianceInsights from "./pages/insights/ComplianceInsights";
import MonitoringInsights from "./pages/insights/MonitoringInsights";

export const baseRoute = "/safetynest";

export const menuItems = [
    {
        label: "Dashboard",
        path: baseRoute,
        icon: DashboardIcon,
        hiddenFromWorkNestUser: true,
        hiddenFromClientUser: false,
    },
    {
        label: 'SafetyNest Score',
        path: `${baseRoute}/score`,
        icon: SafetyNestScoreIcon,
        hiddenFromWorkNestUser: true,
        hiddenFromClientUser: false,
    },
    {
        label: "Audit",
        path: `${baseRoute}/audit`,
        icon: AuditIcon,
        hiddenFromWorkNestUser: true,
        hiddenFromClientUser: false,
        userAccessPermission: userAccessPermissionConstant.CAN_ACCESS_AUDITS,
    },
    {
        label: "Compliance",
        path: `${baseRoute}/${moduleUrls[moduleAreas.COMPLIANCE]}`,
        icon: ComplianceDetailIcon,
        hiddenFromWorkNestUser: true,
        hiddenFromClientUser: false,
        userAccessPermission: userAccessPermissionConstant.CAN_ACCESS_COMPLIANCE,
    },
    {
        label: "Monitoring",
        path: `${baseRoute}/${moduleUrls[moduleAreas.MONITORING]}`,
        icon: MonitoringIcon,
        hiddenFromWorkNestUser: true,
        hiddenFromClientUser: false,
        userAccessPermission: userAccessPermissionConstant.CAN_ACCESS_MONITORING,
    },
    {
        label: "Incidents",
        path: `${baseRoute}/${moduleUrls[moduleAreas.INCIDENTS]}`,
        icon: IncidentsIcon,
        hiddenFromWorkNestUser: true,
        hiddenFromClientUser: false,
        userAccessPermission: userAccessPermissionConstant.CAN_ACCESS_INCIDENTS,
    },
    {
        label: "Risk Assessments",
        path: `${baseRoute}/${moduleUrls[moduleAreas.RISKASSESSMENTS]}`,
        icon: RiskAssessmentsIcon,
        hiddenFromWorkNestUser: true,
        hiddenFromClientUser: false,
        userAccessPermission: userAccessPermissionConstant.CAN_ACCESS_RISKASSESSMENTS,
    },
    {
        label: "Insights",
        icon: InsightsIcon,
        path: `${baseRoute}/insights`,
        hiddenFromWorkNestUser: false,
        hiddenFromClientUser: false,
        requiredSiteRoles: [salesforceRoles.SAFETYNEST_AMENDTEMPLATES],
        children: [
            {
                label: "Audits",
                path: `/${moduleUrls[moduleAreas.AUDIT]}`,
                icon: AuditIcon,
                hiddenFromWorkNestUser: false,
                hiddenFromClientUser: false,
                userAccessPermission: userAccessPermissionConstant.CAN_ACCESS_AUDITS,
            },
            {
                label: "Compliance",
                path: `/${moduleUrls[moduleAreas.COMPLIANCE]}`,
                icon: ComplianceDetailIcon,
                hiddenFromWorkNestUser: false,
                hiddenFromClientUser: false,
                userAccessPermission: userAccessPermissionConstant.CAN_ACCESS_COMPLIANCE,
            },
            {
                label: "Monitoring",
                path: `/${moduleUrls[moduleAreas.MONITORING]}`,
                icon: MonitoringIcon,
                hiddenFromWorkNestUser: false,
                hiddenFromClientUser: false,
                userAccessPermission: userAccessPermissionConstant.CAN_ACCESS_MONITORING,
            }
        ],
    },
    {
        label: "Template Administration",
        icon: AdminIcon,
        path: `${baseRoute}/setup`,
        hiddenFromWorkNestUser: false,
        hiddenFromClientUser: false,
        requiredSiteRoles: [salesforceRoles.SAFETYNEST_AMENDTEMPLATES],
        children: [
            {
                label: "Compliance Setup",
                path: `/${moduleUrls[moduleAreas.COMPLIANCE]}`,
                icon: ComplianceDetailIcon,
                hiddenFromWorkNestUser: false,
                hiddenFromClientUser: false,
                userAccessPermission:
                    userAccessPermissionConstant.CAN_ACCESS_COMPLIANCE,
            },
            {
                label: "Monitoring Setup",
                path: `/${moduleUrls[moduleAreas.MONITORING]}`,
                icon: MonitoringIcon,
                hiddenFromWorkNestUser: false,
                hiddenFromClientUser: false,
                userAccessPermission:
                    userAccessPermissionConstant.CAN_ACCESS_MONITORING,
            },
            {
                label: "Incidents Setup",
                path: `/${moduleUrls[moduleAreas.INCIDENTS]}`,
                icon: IncidentsIcon,
                hiddenFromWorkNestUser: false,
                hiddenFromClientUser: false,
                userAccessPermission: userAccessPermissionConstant.CAN_ACCESS_INCIDENTS,
            },
            {
                label: "Risk Assessments Setup",
                path: `/${moduleUrls[moduleAreas.RISKASSESSMENTS]}`,
                icon: RiskAssessmentsIcon,
                hiddenFromWorkNestUser: false,
                hiddenFromClientUser: false,
                userAccessPermission:
                    userAccessPermissionConstant.CAN_ACCESS_RISKASSESSMENTS,
            },
        ],
    },
    {
        label: "Historic Audits",
        icon: AuditIcon,
        path: `${baseRoute}/site-search?isHistoric=true`,
        hiddenFromWorkNestUser: false,
        hiddenFromClientUser: true,
    },
    {
        label: "Audits",
        icon: AuditIcon,
        path: `${baseRoute}/site-search?isHistoric=false`,
        hiddenFromWorkNestUser: false,
        hiddenFromClientUser: true,
    },
];

export const routes = [
    {
        label: "Dashboard",
        path: baseRoute,
        exact: true,
        component: Dashboard,
    },
    {
        label: 'SafetyNest Score',
        path: `${baseRoute}/score`,
        component: SafetyNestScore,
        exact: true,
    },
    {
        label: "Audit",
        path: `${baseRoute}/audit`,
        component: Audit,
        exact: true,
    },
    {
        label: "Historic Audits",
        path: `${baseRoute}/historic-audits/:siteId`,
        component: HistoricAudits,
        exact: true,
    },
    {
        label: "Add Edit Incident",
        path: `${baseRoute}/${moduleUrls[moduleAreas.INCIDENTS]}/addlog/:displayConfirmation?`,
        component: AddEditIncident,
        exact: true,
    },
    {
        label: "Audits",
        path: `${baseRoute}/meridian-audits/:siteId`,
        component: MeridianAudits,
        exact: true,
    },
    {
        label: "Edit Historic Audit",
        path: `${baseRoute}/historic-audit/:auditId`,
        component: EditHistoricAudit,
        exact: true,
    },
    {
        label: 'Create Historic Audit',
        path: `${baseRoute}/historic-audit`,
        component: CreateHistoricAudit,
        exact: true,
    },
    {
        label: "Site Search",
        path: `${baseRoute}/site-search`,
        component: SiteSearch,
        exact: true,
    },
    {
        label: "Compliance Insights",
        path: `${baseRoute}/insights/${moduleUrls[moduleAreas.COMPLIANCE]}`,
        component: ComplianceInsights,
        exact: true,
    },
    {
        label: "Audit Insights",
        path: `${baseRoute}/insights/${moduleUrls[moduleAreas.AUDIT]}`,
        component: AuditInsights,
        exact: true,
    },
    {
        label: "Monitoring Insights",
        path: `${baseRoute}/insights/${moduleUrls[moduleAreas.MONITORING]}`,
        component: MonitoringInsights,
        exact: true,
    },
    {
        label: "Compliance Admin",
        path: `${baseRoute}/setup/${moduleUrls[moduleAreas.COMPLIANCE]}`,
        component: ComplianceAdmin,
        exact: true,
    },
    {
        label: "Incidents Admin",
        path: `${baseRoute}/setup/${moduleUrls[moduleAreas.INCIDENTS]}`,
        component: IncidentsAdmin,
        exact: true,
    },
    {
        label: "Monitoring Admin",
        path: `${baseRoute}/setup/${moduleUrls[moduleAreas.MONITORING]}`,
        component: MonitoringAdmin,
        exact: true,
    },
    {
        label: "Risk Assessments Admin",
        path: `${baseRoute}/setup/${moduleUrls[moduleAreas.RISKASSESSMENTS]}`,
        component: RiskAssessmentsAdmin,
        exact: true,
    },
    {
        label: "Template Builder",
        path: `${baseRoute}/setup/:moduleArea/templatebuilder`,
        component: TemplateBuilder,
        exact: true,
    },
    {
        label: "Template Builder",
        path: `${baseRoute}/setup/:moduleArea/templatebuilder/edit/:templateVersionId`,
        component: TemplateBuilder,
        exact: true,
    },
    {
        label: "Template Builder",
        path: `${baseRoute}/setup/:moduleArea/templatebuilder/edit/:templateVersionId/:isCopy`,
        component: TemplateBuilder,
        exact: true,
    },
    {
        label: "Compliance",
        path: `${baseRoute}/${moduleUrls[moduleAreas.COMPLIANCE]}`,
        component: Compliance,
        exact: true,
    },
    {
        label: "Incidents",
        path: `${baseRoute}/${moduleUrls[moduleAreas.INCIDENTS]}`,
        component: Incidents,
        exact: true,
    },
    {
        label: "Monitoring",
        path: `${baseRoute}/${moduleUrls[moduleAreas.MONITORING]}`,
        component: Monitoring,
        exact: true,
    },
    {
        label: "Risk Assessments",
        path: `${baseRoute}/${moduleUrls[moduleAreas.RISKASSESSMENTS]}`,
        component: RiskAssessments,
        exact: true,
    },
    {
        label: "Complete Template Form",
        path: `${baseRoute}/:moduleArea/form/:templateFormId`,
        component: CompleteTemplateForm,
        exact: true,
    },
    {
        label: "Template Form History",
        path: `${baseRoute}/:moduleArea/form/history/:templateFormHistoryId`,
        component: ViewFormHistory,
        exact: true,
    },
    {
        label: "Complete Template Form",
        path: `${baseRoute}/:moduleArea/form/:templateFormId/:returnToDashboard`,
        component: CompleteTemplateForm,
        exact: true,
    },  
    {
        label: "Action",
        path: `${baseRoute}/:moduleArea/action/:actionId/:isDri?`,
        component: Action,
        exact: true,
    },
    {
        label: "Add Template Form",
        path: `${baseRoute}/:moduleArea/addtemplateform`,
        component: AddTemplateForm,
        exact: true,
    },
    {
        label: "Add Edit Incident",
        path: `${baseRoute}/${moduleUrls[moduleAreas.INCIDENTS]}/add`,
        component: AddEditIncident,
        exact: true,
    },
    
    {
        label: "Add Edit Incident",
        path: `${baseRoute}/${moduleUrls[moduleAreas.INCIDENTS]}/:incidentId/edit`,
        component: AddEditIncident,
        exact: true,
    },
    {
        label: "Add Risk Assessment",
        path: `${baseRoute}/${moduleUrls[moduleAreas.RISKASSESSMENTS]}/add`,
        component: AddRiskAssessment,
        exact: true,
    },
    {
        label: "View Risk Assessment",
        path: `${baseRoute}/${moduleUrls[moduleAreas.RISKASSESSMENTS]
            }/view/:riskAssessmentId`,
        component: ViewRiskAssessment,
        exact: true,
    },
    {
        label: "View Risk Assessment",
        path: `${baseRoute}/${moduleUrls[moduleAreas.RISKASSESSMENTS]
            }/view/:riskAssessmentId/:returnToDashboard`,
        component: ViewRiskAssessment,
        exact: true,
    },
    {
        label: "Review Risk Assessment",
        path: `${baseRoute}/${moduleUrls[moduleAreas.RISKASSESSMENTS]
            }/review/:riskAssessmentId`,
        component: ReviewRiskAssessment,
        exact: true,
    },
];

export const sidebarButtons = [
    {
        label: "Log Incident",
        icon: <AddIcon />,
        path: `${baseRoute}/${moduleUrls[moduleAreas.INCIDENTS]}/addlog/displayConfirmation=true`,
        module: moduleAreas.INCIDENTS,
        requiredSiteRoles: [salesforceRoles.SAFETYNEST_INCIDENTLOGGING],
        component: AddEditIncident,
    },
]