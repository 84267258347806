import { useMemo } from "react";
import {
    makeStyles,
    Box,
    Container,
    Grid,
    IconButton,
    Typography,
    CircularProgress,
} from "@material-ui/core";
import { useTracking } from "react-tracking";
import PageBase from "../components/PageBase";
import { useParams } from "react-router-dom";
import useKnowledgeBank from "../hooks/queries/useKnowledgeBank";
import { Link } from "react-router-dom";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

const useStyles = makeStyles((theme) => ({
    panel: {
        border: '1px solid #bfbfbf',
        borderRadius: '5px',
        padding: '10px',
        height: '100%'
    },
}));

const Category = () => {
    const { primaryCategoryName } = useParams();

    const { Track } = useTracking({
        page: 'KnowledgeBank'
    });

    const { data, isLoading } = useKnowledgeBank();   

    const classes = useStyles();     

    const subCategories = useMemo(() => {

        let primaryCategory = data?.primaryCategories.find(x => x.name === primaryCategoryName.replaceAll("_", " "));

        if (!primaryCategory)
            return [];

        let articles = data.articles.filter(a => a.primaryCategory === primaryCategory.id);
        let categoryIds = [...new Set(articles.map(a => a.categoryId))];
        let list = data.subCategories.filter(x => categoryIds.includes(x.id));

        list.sort((a, b) => a.name > b.name ? 1 : -1);

        console.log({ list });

        return list;

    }, [data, primaryCategoryName]);

    if (isLoading)
        return (
            <Box display="flex" width="100%" justifyContent="center">
                <CircularProgress />
            </Box>
        );
    
    return (
        <PageBase>
            <Track>
                <Container>
                    <Grid container spacing={2}>
                        {subCategories.map(c =>
                            <Grid item xs={12} md={4} key={c.id}>
                                <Box className={classes.panel} display="flex" alignItems="center" style={{ marginTop: "-8px" }}>
                                    <Box flexGrow={1}><Typography >{c.name}</Typography></Box>
                                    <IconButton component={Link} to={`/knowledge/category/${primaryCategoryName}/${c.name.replaceAll(" ", "_")}`}><ArrowForwardIcon /></IconButton>                              
                                </Box>
                            </Grid>
                        )}
                    </Grid>                   
                </Container>
            </Track>            
        </PageBase>
    );
}

export default Category;
