import { useState, useMemo, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
    Typography,
    Button,
    Box,
    TextField,
    InputAdornment,
    Dialog,
    DialogContent,
    DialogTitle,
    DialogActions,
    makeStyles,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { askQuestion } from "../services/knowledgeBankService";
import { useSnackbar } from "notistack";
import useKnowledgeBank from "../hooks/queries/useKnowledgeBank";
import caseNestDocumentService from "../services/caseNestDocumentService";
import { useTracking } from "react-tracking";
import trackingConstants from "../constants/trackingConstants";
import { iconFromContentType } from "./utils";
import ArticleIcon from "@material-ui/icons/Assignment";

const useStyles = makeStyles(() => ({
    purpleBack: {
        backgroundColor: '#5c45c7',
        color: 'white',
        textAlign: 'center',
        padding: '10px',
        borderRadius: '5px'
    },
    button: {
        backgroundColor: '#5c45c7',
        color: 'white',
        '&:hover': {
            backgroundColor: '#150950'
        }
    },
    stages: {
        position: 'absolute',
        top: 60,
        left: -220,
        width: '200px',
        '& div:first-child': {
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px'
        },
        '& div:last-child': {
            borderBottomLeftRadius: '5px',
            borderBottomRightRadius: '5px'
        },
        '& .selected': {
            backgroundColor: '#150950',
            position: 'relative',
            color: 'white',
            padding: '10px',
            '&:after':
            {
                position: 'absolute',
                backgroundColor: '#150950',
                width: '10px',
                height: '10px',
                right: 0,
                top: '50%',
                marginTop: '-5px',
                marginRight: '-5px',
                content: '""',
                transform: 'rotate(45deg)'
            }
        },
        '& .above': {
            backgroundColor: '#5c45c7',
            color: 'white',
            padding: '10px',
        },
        '& .below': {
            backgroundColor: 'white',
            color: '#150950',
            padding: '10px',
        }
    },
    mobileStage: {
        width: '40px',
        height: '40px',
        borderRadius: '5px',
        '&.selected': {
            backgroundColor: '#150950',
        },
        '&.above': {
            backgroundColor: '#5c45c7',
        },
        '&.below': {
            backgroundColor: 'white',
        }
    },
    answer: {
        marginLeft: '50px',
        marginTop: '10px'
    },
    perfScroll: {
        maxHeight: "calc(100vh - 250px)"
    },
    dashes:
    {
        flex: 1,
        borderTop: '1px dashed'
    },
    searchResults: {
        position: 'absolute',
        width: '100%',
        border: '2px solid #ccc',
        background: 'white',
        minHeight: 100,
        borderRadius: '5px',
        padding: '5px'
    },
    documentPanel: {
        cursor: 'pointer',
        borderRadius: '5px',
        padding: '5px',
        '&:hover': {
            backgroundColor: '#eee'
        }
    }
}))

const KnowledgeBankBar = () => {  

    const [value, setValue] = useState("");
    const [questionOpen, setQuestionOpen] = useState(false);
    const [question, setQuestion] = useState("");
    const [submitting, setSubmitting] = useState(false);
    const [complete, setComplete] = useState();    
    const { data, isLoading } = useKnowledgeBank();    

    const [searchText, setSearchText] = useState("");
    const [debouncedSearchText, setDebouncedSearchText] = useState("");

    const { trackEvent } = useTracking({ page: 'KnowledgeBankBar' });
    const history = useHistory();


    useEffect(() => {

        console.log('search1', searchText);
        const delayInputTimeoutId = setTimeout(() => {
            setDebouncedSearchText(searchText.toLowerCase());
        }, 500);
        return () => clearTimeout(delayInputTimeoutId);
    }, [searchText]);


    useEffect(() => {
        console.log('search2', debouncedSearchText);
    }, [debouncedSearchText]);

    const searchResults = useMemo(() => {
        if (!data)
            return null;

        if (debouncedSearchText.length < 4)
            return null;

        let articles = data.articles.filter(a => a.name.toLowerCase().includes(debouncedSearchText));
        let documents = data.documents.filter(d => d.name?.toLowerCase().includes(debouncedSearchText));

        return { articles, documents };
    }, [data, debouncedSearchText]);

    useEffect(() => {
        console.log({ searchResults });
    }, [searchResults]);


    const { enqueueSnackbar } = useSnackbar();

    const classes = useStyles();

    const submitQuestion =async () => {
        setSubmitting(true);

        try {
            await askQuestion(question);
            setComplete(true);
        }
        catch (err) {   
            enqueueSnackbar(err.message || "Something went wrong!", { variant: "error" });
        } finally {
            setSubmitting(false);
        }        
    };   

    const closeQuestion = () => {
        setQuestionOpen(false);
        setComplete(false);
        setQuestion("");
    }

    const downloadDocument = async (doc) => {

        setSearchText("");
        const response = await caseNestDocumentService.fetchDocument(doc.identifier, 'arraybuffer')
        let blob = new Blob([response], { type: doc.contentType });
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = doc.filename;
        link.click();

        trackEvent({ event: `${trackingConstants.events.downloadKnowledgeDocument}: ${doc.id}` });
    }

    const openArticle = (id) => {
        setSearchText("");
        history.push(`/knowledge/article/${id}`)
    }
    
    return (
        <Box display="flex" alignItems="center" gridGap="5px" style={{ width: '100%' }}>
            <Typography>HR Knowledge Base</Typography>
            <Button component={Link} to="/knowledge">Home</Button>
            <Button component={Link} to="/knowledge/categories">Categories</Button>
            <Button component={Link} to="/knowledge/events">Events</Button>
            <Box flexGrow={1}></Box>
            <div style={{ position: 'relative' }}>
                <TextField
                    value={searchText || ""}
                    onChange={(e) => setSearchText(e.target.value)}
                    variant="outlined"
                    placeholder="Search"
                    size="small"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
                {searchResults &&
                    <div className={classes.searchResults}>
                        <div>Articles</div>
                        {searchResults.articles.slice(0, 5).map(a =>
                            <Box
                                className={classes.documentPanel}
                                display="flex"
                                alignItems="center"
                                gridGap="10px"
                                key={a.id}
                                onClick={() => openArticle(a.id)}
                            >
                                <Box><ArticleIcon/></Box>
                                <Box>{a.name}</Box>
                            </Box>
                        )}
                        <div>Documents</div>
                        {searchResults.documents.slice(0, 5).map(d =>
                            <Box
                                className={classes.documentPanel}
                                display="flex"
                                alignItems="center"
                                gridGap="10px"
                                key={d.id}
                                onClick={() => downloadDocument(d)}
                            >
                                <Box>{iconFromContentType(d.contentType)}</Box>
                                <Box>{d.name}</Box>
                            </Box>
                        )}
                    </div>
                }
            </div>     
            <Button style={{ height: '40px' }} variant="outlined" onClick={() => setQuestionOpen(true)}>Contact Us</Button>
            <Dialog open={questionOpen} maxWidth="md" fullWidth>
                <DialogTitle id="form-dialog-title">How can we help?</DialogTitle>
                <DialogContent>
                    {!complete &&  
                        <TextField
                            disabled={submitting}
                            fullWidth
                            multiline
                            minRows={6}
                            color="secondary"
                            value={question}
                            onChange={(e) => setQuestion(e.target.value)}
                            variant="outlined"
                        />
                    }
                    {complete && <Typography style={{fontSize: "1.5em"}}>Your question has been submitted.  You will receive a reply shortly.</Typography> }
                    <DialogActions>
                        {complete &&
                            <Button color="secondary" variant="contained" onClick={closeQuestion}>
                            Dismiss
                        </Button>}
                        {!submitting && !complete &&
                            <Button color="secondary" variant="outlined" onClick={closeQuestion}>
                                Cancel
                            </Button>
                        }
                        {!complete &&  
                            <Button color="secondary" variant="contained" onClick={submitQuestion} disabled={submitting || !question?.trim()}>
                                {submitting ? "Submitting" : "Submit"}
                            </Button>
                        }                        
                    </DialogActions>                    
                </DialogContent>
            </Dialog>
        </Box>
    );
}

export default KnowledgeBankBar;
