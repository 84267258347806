import axios from "../utils/axios";

const apiUrl = process.env.REACT_APP_LEGAL_URL;

const getData = () => {
    return axios.get(`${apiUrl}/client/getKnowledgeData`);
};

const getArticle = (id) => {
    return axios.get(`${apiUrl}/client/getKnowledgeArticle/${id}`);
};

const getEvent = (id) => {
    return axios.get(`${apiUrl}/client/getKnowledgeEvent/${id}`);
};

export const askQuestion = (question) => {
    return axios.post(`${apiUrl}/client/askknowledgequestion`, { question });
}

const knowledgeBankService = {
    getData,
    getArticle,
    getEvent,
};

export default knowledgeBankService;
