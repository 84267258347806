import React, { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { ReactComponent as WorknestLogo } from "../images/myWorkNest_Purple_Positive_RGB.svg";
import layoutConstants from "../constants/layoutConstants";
import LoginForm from "../components/LoginForm";
import ResetPasswordForm from "../components/ResetPasswordForm";
import loginStageConstants from "../constants/loginStageConstants";
import { useLocation } from "react-router-dom";
import HRSolutionsLogo from "../images/Business-HR-Solutions-Logo.png";
import { isHRS } from "../constants/siteConfig";

const useStyles = makeStyles((theme) => ({
    grid: {
        height: "100vh",
        padding: theme.spacing(2),
        position: "relative",
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center"
    },
    logoWrapper: {
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: theme.spacing(2),
        maxWidth: layoutConstants.loginFormWidth,
    },
    logo: {
        width: "100%",
    },
    greeting: {
        marginTop: theme.spacing(2),
        fontSize: "32px",
        fontWeight: "bold",
        textAlign: "center",
    },
    greetingSub: {
        textAlign: "center",
        fontSize: "16px",
        marginBottom: theme.spacing(5),
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    },
    buttons: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        maxWidth: layoutConstants.loginFormWidth,
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
    },
}));

function Login() {
    const classes = useStyles();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const email = queryParams.get("email");
    const stage = queryParams.get("stage");
    const [loginStage, setLoginStage] = useState(stage || loginStageConstants.LOGIN);
    const [emailAddress, setEmailAddress] = useState(email || '');

    const onPasswordResetClick = () => setLoginStage(loginStageConstants.RESETPASSWORD)
    const onBackClick = () => setLoginStage(loginStageConstants.LOGIN)

    return (
        <Grid className={classes.grid} container>
            <Grid className={classes.formContainer} item xs={12} sm={6} xl={3}>
                <div className={classes.logoWrapper}>
                    {isHRS ? <img className={classes.logo} src={HRSolutionsLogo} alt="Business HR Logo" /> : <WorknestLogo className={classes.logo} />}
                </div>
                {loginStage === loginStageConstants.LOGIN &&
                    <LoginForm
                        emailAddress={emailAddress}
                        setEmailAddress={setEmailAddress}
                        onPasswordResetClick={onPasswordResetClick}
                    />
                }
                {loginStage === loginStageConstants.RESETPASSWORD &&
                    <ResetPasswordForm
                        emailAddress={emailAddress}
                        setEmailAddress={setEmailAddress}
                        onBackClick={onBackClick}
                    />
                }
            </Grid>
        </Grid>
    );
}

export default Login;
