import { useMemo } from "react";
import { Link } from "react-router-dom";
import {
    makeStyles,
    Box,
    Container,
    Typography,
    Divider,
    Button,
    Grid,
    IconButton,
    CircularProgress,
} from "@material-ui/core";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useTracking } from "react-tracking";
import PageBase from "../components/PageBase";
import Epic from "./Epic";
import ArticleTile from "./ArticleTile";
import useKnowledgeBank from "../hooks/queries/useKnowledgeBank";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
    panel: {
        border: '1px solid #bfbfbf',
        borderRadius: '5px',
        padding: '10px',
        height:'100%'
    },
}));

const KnowledgeBank = () => {
    const classes = useStyles();
    const { Track } = useTracking({
        page: 'KnowledgeBank'
    });

    const { data, isLoading } = useKnowledgeBank();

    const primaryCategories = data?.primaryCategories;

    const recentArticles = useMemo(() => {
        if (!data)
            return [];

        let list = [...data.articles].sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1)).splice(0, 10);        

        return list;

    }, [data]);

    const upcoming = useMemo(() => {
        if (!data)
            return [];

        let list = [...data.events].sort((a, b) => (a.start > b.start ? 1 : -1)).splice(0, 3);

        return list;

    }, [data]);

    if (isLoading)
        return (
            <Box display="flex" width="100%" justifyContent="center">
                <CircularProgress />
            </Box>
        );

    //const contactButton = <Button variant="outlined" color="secondary">Contact Us</Button>;
    const categoriesButton = <Button variant="contained" color="secondary" component={Link} to="/knowledge/categories">View Categories</Button>;    

    return (
        <PageBase>
            <Track>                
                <Container>
                    <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center" gridGap="15px">
                        <Epic
                            title="HR Knowledge Base"
                            main="Access essential policies, locate key documentation, and connect with our Employee Relations experts for guidance when you need it."
                            sub={<>Please note: This service is available to registered users only.  For further information, contact HRPortal@hrsolutions-uk.com or call <span style={{ whiteSpace: "nowrap" }} > 0333 247 2004</span> for assistance</>}
                            buttons={[categoriesButton]}
                        />
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={8}>
                                <Typography style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Recent</Typography>
                                <Divider style={{ margin: '15px 0' }} />
                                <Grid container spacing={2}>
                                    {recentArticles.map(a =>
                                        <Grid item xs={12} md={6} key={a.id}>
                                            <ArticleTile article={a} />  
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Typography style={{ fontSize: '1.2em', fontWeight: 'bold' }}>Upcoming Events</Typography>
                                <Divider style={{ margin: '15px 0' }} />
                                <Box display="flex" gridGap="5px" flexDirection="column">
                                    {upcoming.map(u =>
                                        <Box key={u.id} className={classes.panel} display="flex" flexDirection="column" gridGap="5px">
                                            <Typography>{format(new Date(u.start), "d MMM yyyy")}</Typography>
                                            <Typography>{u.name}</Typography>
                                        </Box>
                                    )}
                                </Box>                                
                                <Typography style={{ fontSize: '1.2em', fontWeight: 'bold', marginTop: '15px' }}>Categories</Typography>
                                <Divider style={{ margin: '15px 0' }} />
                                <Box display="flex" gridGap="5px" flexDirection="column">
                                    {primaryCategories.map(c =>
                                        <Box key={c.id} className={classes.panel} display="flex" gridGap="5px" alignItems="center">
                                            <Box flexGrow={1}><Typography >{c.name}</Typography></Box>
                                            <IconButton component={Link} to={`/knowledge/category/${c.name.replaceAll(" ", "_")}`}><ArrowForwardIcon/></IconButton>
                                        </Box>
                                    )}
                                </Box>        
                            </Grid>
                        </Grid>                        
                    </Box>                    
                </Container>                
            </Track>
        </PageBase>
    );
}

export default KnowledgeBank;
