import { useMemo } from "react";
import {
    makeStyles,
    Box,
    Container,
    Divider,
    Typography,
    Grid,
    CircularProgress,
    Button,
} from "@material-ui/core";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import { useHistory, useParams } from "react-router-dom";
import { useTracking } from "react-tracking";
import PageBase from "../components/PageBase";
import useKnowledgeBank from "../hooks/queries/useKnowledgeBank";
import useKnowledgeBankEvent from "../hooks/queries/useKnowledgeBankEvent";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
    panel: {
        border: '1px solid #bfbfbf',
        borderRadius: '5px',
        padding: '10px',
    },
}));

const Event = () => {
    const { id } = useParams();
    const { data, isLoading } = useKnowledgeBank();
    const { data: fullEvent } = useKnowledgeBankEvent(id);

    const history = useHistory();

    console.log({ fullEvent });

    console.log({ isLoading, data });

    console.log({ id, parsed: parseInt(id) });   

    const event = useMemo(() => data?.events.find(e => e.id === parseInt(id)), [data?.events, id]);

    console.log({ event });    

    const classes = useStyles();
    const { Track } = useTracking({ page: `KnowledgeBank-Article:${id}` });

    const contentClickHandler = (e) => {
        const targetLink = e.target.closest('a');
        if (!targetLink)
            return;

        e.preventDefault();

        let href = e.target.getAttribute("href");
        if (href.startsWith("/document")) {
            //let id = parseInt(href.replace("/document/", ""));
            //downloadDocumentById(id);
        } else if (href.startsWith("/")) {
            history.push(href);
        } else {
            window.open(href, '_blank');
        }
    };

    if (isLoading)
        return (
            <Box display="flex" width="100%" justifyContent="center">
                <CircularProgress />
            </Box>
        );
  
    return (
        <PageBase>
            <Track>
                <Container>
                    <Box display="flex" flexDirection="column" gridGap="15px">
                        <Typography>{event.name}</Typography>                        
                        <Divider />
                        {!fullEvent &&
                            <Box display="flex" width="100%" justifyContent="center">
                                <CircularProgress />
                            </Box>}
                        {fullEvent &&
                            <Grid container spacing={3}>
                                <Grid item xs={8}>
                                    <div dangerouslySetInnerHTML={{ __html: fullEvent.eventDetails }} onClick={contentClickHandler} />
                                </Grid>
                                <Grid item xs={4}>
                                    <Box className={classes.panel} display="flex" flexDirection="column" gridGap="10px">
                                        <Typography>Details:</Typography>
                                        <Box display="flex" alignItems="center" gridGap="10px">
                                            <CalendarIcon />
                                            <Typography>{format(new Date(event.start), "eee, d MMM yyyy")}</Typography>
                                        </Box>
                                        {event.registerUrl && <Button href={event.registerUrl} target="_blank" variant="contained" color="secondary">Register</Button>}                                        
                                    </Box>
                                </Grid>
                            </Grid>
                        }
                    </Box>
                </Container>
            </Track>            
        </PageBase>
    );
}

export default Event;
