import React, { useState } from "react";
import {
    TextField, makeStyles, Button, Divider, Typography, FormControl, InputLabel, IconButton, InputAdornment,
    OutlinedInput, FormHelperText
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { validEmailRegex } from "../../src/utils/regexUtils";
import { useAuth } from "../contexts/authContext";
import MicrosoftLoginButton from "./MicrosoftLoginButton";
import GoogleLoginButton from "./GoogleLoginButton";

const useStyles = makeStyles((theme) => ({
    orSplitter: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    orLines: {
        width: '40%'
    },
    orText: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        font: "12px"
    },
    inputField: {
        width: "100%",
        color: "black",
        marginBottom: theme.spacing(2),
        '& .MuiOutlinedInput-root': { 
            '&.Mui-error .MuiOutlinedInput-notchedOutline': { 
                borderColor: theme.palette.error.main,
            },
        },
    },
    inputFieldEmail: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    buttons: {
        display: "flex",
        flexDirection: "column",
        gap: theme.spacing(2),
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
    },
    errorInputField: {
        border: `1px solid ${theme.palette.error.main}`,
    },
    passwordResetInfo: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: theme.spacing(3),
        background: '#E9FFED',
    },
    greenIcon: {
        color: 'green',
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
    },
    messageText: {
        flexGrow: 1,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    editEmailButton:
    {
        cursor: "pointer",
        color: theme.palette.secondary.dark,
        fontWeight: 'bold',
    },
    signInTroubleButton:
    {
        marginTop: theme.spacing(2), 
        alignSelf: 'center',
        color: theme.palette.secondary.main,
        fontWeight: 'bold',
    },
    mainButton:
    {
        padding: theme.spacing(1),
        height: "48px"
    },
    greeting: {
        marginTop: theme.spacing(2),
            fontSize: "32px",
            fontWeight: "bold",
          textAlign: "center",
        },
        greetingSub: {
            textAlign: "center",
            fontSize: "16px",
            marginBottom: theme.spacing(5),
        },
}));

function LoginForm({ emailAddress, setEmailAddress, onPasswordResetClick }) {
    const classes = useStyles();
    const [invalidEmail, setInvalidEmail] = useState(false); 
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [invalidPassword, setInvalidPassword] = useState(false); 
    const [loginFailure, setLoginFailure] = useState(false); 
    const [loginFailureMessage, setLoginFailureMessage] = useState(false); 
    const { loginViaLocalAccount } = useAuth();

    const validateEmailAddress = () => {
        const isValidEmail = emailAddress.trim() !== '' && emailAddress.match(validEmailRegex);
        setInvalidEmail(!isValidEmail);
        return isValidEmail;
    };

    const validatePassword = () => {
        const invalidPassword = password.trim() === '';
        setInvalidPassword(invalidPassword);
        return invalidPassword;
    };
    
    const handleSignInButtonClick = (e) => {
        e.preventDefault();
            validateEmailAddress();
            validatePassword();
                        
            if (emailAddress.trim() !== '' && emailAddress.match(validEmailRegex) && password.trim() !== '') {
                loginViaLocalAccount({ email: emailAddress, password: password })
                    .then(res => {
                       
                    })
                    .catch(err => {
                        console.error('Login fail:', err);
                        setLoginFailure(true);
                        setLoginFailureMessage("Incorrect email address or password");
                    });
            }
    };
    const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSignInButtonClick(e);
        }
    };

    function handlePasswordChange(password) {
        setLoginFailure(false);
        setPassword(password);
    }

    return (
        <>
            <Typography className={classes.greeting}>Welcome</Typography>
            <Typography className={classes.greetingSub}>Sign in to continue</Typography>
            <div className={classes.buttons}>
                <MicrosoftLoginButton className={classes.socialButton} />
                <GoogleLoginButton className={classes.socialButton} />                
            </div>
            <div className={classes.orSplitter}>
                <Divider className={classes.orLines} />
                <Typography className={classes.orText}>OR</Typography>
                <Divider className={classes.orLines} />
            </div>
            <TextField
                className={classes.inputFieldEmail}
                label={"Email"}
                variant="outlined"
                value={emailAddress}
                inputProps={{ maxLength: 320 }}
                helperText={invalidEmail && "Please enter a valid email address."}
                error={invalidEmail}
                onChange={(e) => setEmailAddress(e.target.value)}
            />
            <form onSubmit={handleSignInButtonClick}>
                <FormControl variant="outlined"
                    className={classes.inputField}
                    error={loginFailure || invalidPassword} 
                >
                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                    <OutlinedInput
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        value={password}
                        onChange={(e) => handlePasswordChange(e.target.value)}
                        autoFocus={true}
                        autoComplete="new-password"
                        onKeyPress={handleKeyPress}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleTogglePasswordVisibility}
                                    edge="end"
                                >
                                    {!showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText error>{invalidPassword && "Password cannot be left empty"}</FormHelperText>
                    <FormHelperText error>{!invalidPassword && loginFailure && loginFailureMessage}</FormHelperText>
                </FormControl>
            </form>
            <Button
                className={classes.mainButton}
                variant="contained"
                color="secondary"
                onClick={handleSignInButtonClick}
            >
                Sign in
            </Button>
            <Button className={classes.signInTroubleButton} onClick={onPasswordResetClick}
                >Forgot your password?
            </Button>
        </>
    );
}

export default LoginForm;