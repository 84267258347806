import React, { useEffect } from "react";
import { Switch } from "react-router-dom";
import { useTracking } from "react-tracking";
import AuthLayout from "../layouts/AuthLayout";
import trackingService from "../services/trackingService";
import trackingConstants from "../constants/trackingConstants";
import { protectedRoutes } from "../routes";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { SnackbarProvider as VendingMachine } from "notistack";
import RouteMapper from "./RouteMapper";
import TutorialTracker from "./TutorialTracker";
import CookieJar from "./CookieJar";
import { useAuth } from "../contexts/authContext";
import { isHRS } from "../constants/siteConfig";

const AuthenticatedApp = () => {
    const { impersonatingUser, user } = useAuth();
    const { Track } = useTracking(
        {
            app: trackingConstants.appName,
            isImpersonationSession: !!impersonatingUser,
        },
        {
            dispatch: trackingService.sendTrackingData,
            process: (ownTrackingData) =>
                ownTrackingData.page
                    ? { event: trackingConstants.events.pageView }
                    : null,
        }
    );

    useEffect(() => {

        if (isHRS)
            return;

        var decodedRoles = Array.isArray(user.details.roles) ? user.details.roles : user.details.roles !== undefined ? [user.details.roles] : [];
        var decodedApps = Array.isArray(user.details.apps) ? user.details.apps : user.details.apps !== undefined ? [user.details.apps] : [];

        let roles = decodedRoles.length === 0 ? [] : decodedRoles.map(role => role.split(':')[0]);
        let apps = decodedApps.length === 0 ? [] : decodedApps.map(role => role.split(':')[0]);

        window.InlineManual("boot", { name: user.details.firstName, uid: user.details.email, roles, apps }, { language: 'en' });
        window.InlineManual("update");

        return () => {
            window.InlineManual("shutdown");
        }
    }, [user]);

    return (
        <Track>
            <VendingMachine maxSnack={3}>
                <AuthLayout>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TutorialTracker />
                        <CookieJar />
                        <RouteMapper routes={protectedRoutes} />
                    </MuiPickersUtilsProvider>
                </AuthLayout>
            </VendingMachine>
        </Track>
    );
};

export default AuthenticatedApp;
