import { useEffect } from "react";
import { useHistory } from "react-router-dom";

function RequiresRoleWrapper({ hasRole, children }) {
  const history = useHistory();

    useEffect(() => {
        console.log({hasRole});
    if (!hasRole) history.push("/");
  }, [hasRole]);

  return children;
}

export default RequiresRoleWrapper;
