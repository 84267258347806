import React from "react";
import RouteMapper from "./RouteMapper";
import { publicRoutes } from "../routes";
import { SnackbarProvider as VendingMachine } from "notistack";

const UnauthenticatedApp = () => {
    return (
        <VendingMachine maxSnack={3}>
            <RouteMapper routes={publicRoutes} />
        </VendingMachine>
    );
};

export default UnauthenticatedApp;
